import React, { createContext, useReducer } from "react";
import { DRAWERS } from "./types";
import type { DrawerAction, DrawerState, DrawerStateLabel, TDrawerContext } from "./types";

const stateKeys = Object.values(DRAWERS);

export const defaultDrawerState = stateKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {} as DrawerState);

export const drawerReducer = (state: DrawerState, action: DrawerAction) => {
  if (action.type === "RESET") return defaultDrawerState;

  switch (action.type) {
    case "SHOW":
      return { ...state, [action.payload]: true };
    case "HIDE":
      return { ...state, [action.payload]: false };
    case "TOGGLE":
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return state;
  }
};

export const drawerActions = (dispatch: (value: DrawerAction) => void) => {
  return {
    show: (payload: DrawerStateLabel) => dispatch({ type: "SHOW", payload }),
    hide: (payload: DrawerStateLabel) => dispatch({ type: "HIDE", payload }),
    toggle: (payload: DrawerStateLabel) => dispatch({ type: "TOGGLE", payload }),
    reset: () => dispatch({ type: "RESET" }),
  };
};

const context = createContext<TDrawerContext | null>(null);
const { Provider } = context;

export function AccountDrawersProvider({ children }: { children: React.ReactElement }) {
  const [drawerState, dispatchDrawerState] = useReducer(drawerReducer, defaultDrawerState);
  const actions = drawerActions(dispatchDrawerState);

  return <Provider value={{ drawerState, ...actions }}>{children}</Provider>;
}

export default context;

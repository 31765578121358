import { drawerActions } from ".";

export type DrawerAction = { type: "SHOW" | "HIDE" | "TOGGLE"; payload: keyof typeof DRAWERS } | { type: "RESET" };
export type ShowAction = (payload: DrawerStateLabel) => void;
export type HideAction = (payload: DrawerStateLabel) => void;
export type ToggleAction = (payload: DrawerStateLabel) => void;
export type ResetAction = () => void;
export type DrawerType = keyof typeof DRAWERS;
export type DrawerTypeValues = (typeof DRAWERS)[DrawerType];
export type DrawerStateLabel = DrawerTypeValues | DrawerType;
export type DrawerState = { [key in DrawerTypeValues]: boolean };
export type DrawerActions = ReturnType<typeof drawerActions>;

export type TDrawerContext = {
  drawerState: DrawerState;
  show: ShowAction;
  hide: HideAction;
  toggle: ToggleAction;
  reset: ResetAction;
};

export enum DRAWERS {
  // INTEREST_HISTORY = "INTEREST_HISTORY",
  ADD_CUSTOMER = "ADD_CUSTOMER",
  ADD_NOTE = "ADD_NOTE",
  BALANCE_OFFSET = "BALANCE_OFFSET",
  CHANGE_CREDIT_LIMIT = "CHANGE_CREDIT_LIMIT",
  CONFIRM_STATUS = "CONFIRM_STATUS",
  CREATE_LOAN = "CREATE_LOAN",
  DISPUTE_CHARGE = "DISPUTE_CHARGE",
  EDIT_CUSTOMER = "EDIT_CUSTOMER",
  EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD",
  EDIT_STATUS = "EDIT_STATUS",
  INTEREST_RATES = "INTEREST_RATES",
  NON_FINANCED_AMOUNT = "NON_FINANCED_AMOUNT",
  OFFSET_LOAN = "OFFSET_LOAN",
  PAYMENTS = "PAYMENTS",
  PREVIEW_PAYMENT = "PREVIEW_PAYMENT",
  PREVIEW_REVERSE_PAYMENT = "PREVIEW_REVERSE_PAYMENT",
  REQUEST_ACCOUNT_STATUS_CHANGE = "REQUEST_ACCOUNT_STATUS_CHANGE",
  REQUEST_CHARGE_DISPUTE = "REQUEST_CHARGE_DISPUTE",
  RESTRUCTURE_LOAN = "RESTRUCTURE_LOAN",
  LOAN_DETAILS = "LOAN_DETAILS",
  REVERSE_OFFSET = "REVERSE_OFFSET",
  REVERSE_PAYMENT = "REVERSE_PAYMENT",
  SCRA_BENEFITS = "SCRA_BENEFITS",
  STATEMENT = "STATEMENT",
  TRANSACTION_DETAILS = "TRANSACTION_DETAILS",
  WAIVE_FEE = "WAIVE_FEE",
}

export enum ACCOUNT_DRAWER_DEPTHS {
  "PAYMENTS" = 5,
  "PREVIEW_PAYMENT" = 10,
  "REVERSE_PAYMENT" = 15,
  "PREVIEW_REVERSE_PAYMENT" = 20,
  "EDIT_STATUS" = 25,
  "CONFIRM_STATUS" = 30,
  "OFFSET_LOAN" = 35,
  "RESTRUCTURE_LOAN" = 40,
  "LOAN_DETAILS" = 45,
}
